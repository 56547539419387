var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"groupsAutocomplete",attrs:{"items":_vm.groups,"search-input":_vm.groupSearch,"menu-props":_vm.menuProps,"loading":_vm.isLoadingGroups,"item-value":"id","item-text":"name","flat":"","small-chips":"","solo":"","filled":"","multiple":"","hide-no-data":_vm.isNotEmptyGroups,"no-filter":"","hide-selected":"","hide-details":"","return-object":_vm.returnObject,"deletable-chips":"","background-color":"secondary","label":"Введите название группы или воспользуйтесь фильтром..."},on:{"update:searchInput":function($event){_vm.groupSearch=$event},"update:search-input":function($event){_vm.groupSearch=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"two-line":_vm.mdAndDown,"dense":_vm.mdAndDown}},on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.course)+" курс)")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.speciality.profile.name)}})],1),_c('v-list-item-action',[_c('v-chip',{attrs:{"outlined":"","small":_vm.mdAndDown,"color":"primary"}},[_vm._v(_vm._s(item.speciality.education_form.name))])],1)],1)]}},(!_vm.noFilterAppend)?{key:"append",fn:function(){return [_c('v-badge',{attrs:{"dot":"","overlap":"","color":"primary","value":_vm.hasQueryProps}},[_c('v-btn',{staticClass:"included",attrs:{"icon":"","text":""},on:{"click":_vm.toggleGroupsFilter}},[_c('v-icon',[_vm._v("filter_list")])],1)],1)]},proxy:true}:null,{key:"append-item",fn:function(){return [_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLastPageGroups && !_vm.isLoadingGroups),expression:"!isLastPageGroups && !isLoadingGroups"},{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersectGroups,
        options: {
          threshold: [0, 0.5, 1.0],
        },
      }),expression:"{\n        handler: onIntersectGroups,\n        options: {\n          threshold: [0, 0.5, 1.0],\n        },\n      }"}],staticClass:"text-button",attrs:{"color":"primary","input-value":true}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Загрузить ещё")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}})}
var staticRenderFns = []

export { render, staticRenderFns }