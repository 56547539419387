



































































































import Vue from 'vue';
import { Group } from 'typings/interface/group';
import { Attachment } from 'typings/interface/attachment';
import SelectDateStartSchedule from '@/components/SelectDateStartSchedule/index.vue';
import SelectDateEndSchedule from '@/components/SelectDateEndSchedule/index.vue';
import SelectAttachments from '@/components/SelectAttachments/index.vue';
import SelectScheduleType from '@/components/SelectScheduleType/index.vue';
import SelectSignatureDate from '@/components/SelectSignatureDate/index.vue';
import TextFieldScheduleDescription from '@/components/TextFieldScheduleDescription/index.vue';
import AlertGroup from '@/components/AlertGroup/index.vue';
import RepositoryFactory from '@/api/RepositoryFactory';

const AttachmentsRepository = RepositoryFactory.get('attachments');
const ScheduleRepository = RepositoryFactory.get('schedule');

export default Vue.extend({
  components: {
    SelectDateStartSchedule,
    SelectDateEndSchedule,
    SelectAttachments,
    SelectScheduleType,
    SelectSignatureDate,
    TextFieldScheduleDescription,
    AlertGroup,
  },
  props: {
    groups: {
      type: Array,
      default: [] as Array<Group>,
    },
  },
  data:() => ({
    selectedStartDateSchedule: null as (null | string),
    selectedEndDateSchedule: null as (null | string),
    selectedSignatureDate: null as (null | string),
    selectedScheduleType: null as (null | number),
    introducedScheduleDescription: null as (null | string),
    selectedScheduleAttachments: [] as Array<Attachment>,
    loadedAttachments: [] as Array<Number>,
    isLoadingAttachments: false,
    isLoadingSchedule: false,
    snackbarQueue: [] as Array<any>,
    changeSignatureDate: false,
    snackbarFormDefault: {
      active: false,
      type: undefined,
      outlined: false,
      action: false,
      actionColor: 'red',
      timeout: null as (null | number),
      text: null as (null | string),
      groups: [] as Array<any>,
    },
  }),
  computed: {
    dateStartIsNotSelected() {
      return !this.selectedStartDateSchedule;
    },
    datesIsNotSelected() {
      return !(this.selectedStartDateSchedule && this.selectedEndDateSchedule);
    },
    typeOrDescriptionIsNotIntroduced() {
      return !(this.selectedScheduleType !== null || this.introducedScheduleDescription);
    },
    cantLoadSchedules() {
      return (
        !(this.selectedStartDateSchedule && this.selectedEndDateSchedule)
        || !(this.selectedScheduleType !== null || this.introducedScheduleDescription)
        || this.selectedScheduleAttachments.length <= 0
        || this.groups.length <= 0
      );
    },
    isNotSelectedAttachments() {
      return !(this.selectedScheduleAttachments.length > 0);
    },
  },
  watch: {
    selectedStartDateSchedule(value: (null | string)) {
      if (value === null) {
        this.selectedEndDateSchedule = null;
        this.selectedScheduleType = null;
        this.introducedScheduleDescription = null;
        this.selectedScheduleAttachments = [];
      } else {
        this.snackbarQueue = [];
      }
    },
    selectedEndDateSchedule(value: (null | string)) {
      if (value === null) {
        this.selectedScheduleType = null;
        this.introducedScheduleDescription = null;
        this.selectedScheduleAttachments = [];
      }
    },
    selectedScheduleType(value: (null | number)) {
      if (value === null) {
        this.selectedScheduleAttachments = [];
      }
    },
    introducedScheduleDescription(value: (null | number)) {
      if (value === null) {
        this.selectedScheduleAttachments = [];
      }
    },
    snackbarQueue(items: any) {
      const lastItem = items[items.length - 1];

      if (lastItem) {
        if (lastItem.active && lastItem.timeout) {
          setTimeout(() => {
            items.splice(lastItem.index, 1);
          }, lastItem.timeout);
        }
      }
    },
    changeSignatureDate(value: boolean) {
      if (!value) {
        this.selectedSignatureDate = null;
      }
    },
  },
  methods: {
    clearFormAddingSchedule() {
      const vFormAddingSchedule: any = this.$refs.formAddingSchedule;
      vFormAddingSchedule.reset();
    },
    clearLoadedAttachments() {
      this.loadedAttachments = [];
    },
    async loadAttachments() {
      this.isLoadingAttachments = true;
      await Promise.all(this.selectedScheduleAttachments.map(
        async (attachment: any) => {
          const formData = new FormData();
          formData.append('file', attachment);

          const response = await AttachmentsRepository.set(formData);
          this.loadedAttachments.push(response.data.id);
        },
      ));
      this.isLoadingAttachments = false;
    },
    loadSchedules() {
      if (!this.cantLoadSchedules) {
        this.loadAttachments().then(
          () => {
            this.setSnackSuccessMessage({
              active: true,
              text: 'Файлы расписания успешно загружены',
              type: 'success',
              actionColor: 'success',
              timeout: 3000,
            });
            this.setSchedule();
          },
          (error) => {
            this.setSnackErrorMessage(error);
          },
        );
      }
    },
    async setSchedule() {
      this.isLoadingSchedule = true;
      const groupsIds = this.groups.map((group: any) => group.id);
      let queryParams = {
        date_from: this.selectedStartDateSchedule,
        date_to: this.selectedEndDateSchedule,
        groups: groupsIds,
        attachments: this.loadedAttachments,
        sign_date: this.selectedSignatureDate,
      };

      if (this.selectedScheduleType !== null) {
        queryParams = Object.assign(queryParams, {
          type: this.selectedScheduleType,
        });
      }

      if (this.introducedScheduleDescription) {
        queryParams = Object.assign(queryParams, {
          description: this.introducedScheduleDescription,
        });
      }

      try {
        await ScheduleRepository.set(queryParams);
        this.clearFormAddingSchedule();
        this.clearLoadedAttachments();
        this.setSnackSuccessMessage({
          active: true,
          text: `
            Расписание успешно загружено на все выбранные группы.
            Чтобы удостовериться, кликните на любую группу ниже:
          `,
          type: 'success',
          actionColor: 'success',
          action: true,
          groups: this.groups,
        });
      } catch (error) {
        this.setSnackErrorMessage(error);
      }
      this.isLoadingSchedule = false;
    },
    setSnackErrorMessage(error: any) {
      const errorsObj = error.data;
      const errors = [] as Array<any>;

      Object.keys(errorsObj).forEach((key) => {
        errors.push(`${key}: ${errorsObj[key]}`);
      });

      const snackbar = Object.assign({}, this.snackbarFormDefault, {
        active: true,
        text: errors.join(' и '),
        type: 'error',
      });
      this.snackbarQueue.push(snackbar);
    },
    setSnackSuccessMessage(snackbarSettings: any) {
      const snackbar = Object.assign({}, this.snackbarFormDefault, snackbarSettings);
      this.snackbarQueue.push(snackbar);
    },
    closeSnackbar(snackbar: any) {
      snackbar.active = false;
      this.snackbarQueue.splice(snackbar.index, 1);
    },
  },
});
