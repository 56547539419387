var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":_vm.educationForms,"menu-props":_vm.menuProps,"disabled":_vm.facultyIsEmpty,"item-text":"name","item-value":"id","flat":"","filled":"","hide-details":"","clearable":"","label":"Выберите форму обучения"},on:{"change":_vm.selected},scopedSlots:_vm._u([(!_vm.mdAndDown)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"280","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-content',_vm._g({},on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}}:{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"dense":""}},on),[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]}}],null,true),model:{value:(_vm.selectedEducationForm),callback:function ($$v) {_vm.selectedEducationForm=$$v},expression:"selectedEducationForm"}})}
var staticRenderFns = []

export { render, staticRenderFns }