import Repository from '../Repository';

const resource = '/schedule/attachment-set';

export default {
  set(attachment: any) {
    return Repository.post(`${resource}/`, attachment);
  },
  delete(id: number) {
    return Repository.delete(`${resource}/${id}`);
  },
};
