















import Vue from 'vue';
import SCHEDULE_TYPES from '@/dictionaries/types';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  data:() => ({
    scheduleTypes: SCHEDULE_TYPES,
    selectedType: null as (null | number),
  }),
  watch: {
    value: {
      handler(value: (null | number)) {
        this.selectedType = value;
      },
      immediate: true,
    },
    selectedType(value: (null | number)) {
      this.$emit('update:value', value);
    },
  },
});
