import LoginRepository from './repositories/loginRepository';
import LogoutRepository from './repositories/logoutRepository';
import FacultiesRepository from './repositories/facultiesRepository';
import SpecialitiesRepository from './repositories/specialitiesRepository';
import GroupsRepository from './repositories/groupsRepository';
import ScheduleRepository from './repositories/scheduleRepository';
import AttachmentsRepository from './repositories/attachmentsRepository';
import BlobRepository from './repositories/blobRepository';

type Name = {
  [key: string]: any,
};

const repositories: Name = {
  login: LoginRepository,
  logout: LogoutRepository,
  faculties: FacultiesRepository,
  specialities: SpecialitiesRepository,
  groups: GroupsRepository,
  schedule: ScheduleRepository,
  attachments: AttachmentsRepository,
  blob: BlobRepository,
};

const RepositoryFactory = {
  get: (name: string) => repositories[name],
};

export default RepositoryFactory;
