const state = {
  fontSize: localStorage.getItem('font-size') || '1',
  theme: localStorage.getItem('theme') || '',
};

const getters = {
  fontSize: (thisState: any) => thisState.fontSize,
  theme: (thisState: any) => thisState.theme,
};

const actions = {
  setFontSize({ commit }: any, fontSize: string) {
    commit('setFontSize', fontSize);
  },
  setTheme({ commit }: any, theme: boolean) {
    commit('setTheme', theme);
  },
};

const mutations = {
  setFontSize(thisState: any, fontSize: string) {
    thisState.fontSize = fontSize;
    localStorage.setItem('font-size', fontSize);
  },
  setTheme(thisState: any, theme: string) {
    thisState.theme = theme;
    localStorage.setItem('theme', `${theme}`);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
