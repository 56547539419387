



















import Vue from 'vue';

export default Vue.extend({
  props: {
    chair: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getChairLink() {
      const chairLink = this.chair.link as any;
      if (chairLink) {
        return chairLink;
      }

      return '';
    },
  },
  methods: {
    notify(key: number) {
      if (!this.getChairLink) {
        this.$emit('notify', key);
      }
    },
  },
});
