











// Imports
import Vue from 'vue';
import AUTH from '@/store/actions/auth';

export default Vue.extend({
  data:() => ({}),
  methods: {
    logout() {
      this.$store.dispatch(AUTH.LOGOUT)
        .then(() => {
          this.$router.push('/login');
        })
        .catch((error) => {});
    },
  },
});
