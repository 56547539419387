












import Vue from 'vue';
import { Attachment } from 'typings/interface/attachment';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: [] as Array<Attachment>,
    },
  },
  data:() => ({
    selectedAttachments: [] as Array<Attachment>,
  }),
  watch: {
    value: {
      handler(value: Array<Attachment>) {
        this.selectedAttachments = value;
      },
      immediate: true,
    },
    selectedAttachments(value: Array<Attachment>) {
      this.$emit('update:value', value);
    },
  },
});
