<template lang="pug">
  v-dialog(
    v-model="dialogSettings"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  )
    template(v-slot:activator="{ on }")
      v-btn(
        icon
        v-on="on"
      )
        v-icon visibility
    v-card(tile)
      v-toolbar(
        dark
        color="primary"
      )
        v-btn(
          icon
          dark
          @click="closeDialogSettings()"
        )
          v-icon close
        v-toolbar-title.title Настройки
      v-container(fluid)
        v-row.no-gutters
          v-col(md=6 sm=12 xs=12)
            v-list(
              subheader
            )
              p.subtitle-1.px-0.mb-2.mt-2 Интерфейс
              v-subheader.px-0 Размер шрифта
              v-list-item.px-0.mb-4
                v-select(
                  v-model="selectedFontSize"
                  :items="fontSizes"
                  item-text="name"
                  item-value="id"
                  flat
                  filled
                  hide-details
                  label="Выберите размер шрифта"
                  return-object
                  @change="setFontSize()"
                )
              v-subheader.px-0 Темная тема
              v-list-item.px-0
                v-switch.mt-0(
                  v-model="darkTheme"
                  hide-details
                  label="Темная тема"
                )
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  data:() => ({
    dialogSettings: false,
    fontSizes: [
      { id: 1, name: 'Обычный', value: '16px' },
      { id: 2, name: 'Средний', value: '18px' },
      { id: 3, name: 'Крупный', value: '20px' },
    ],
    selectedFontSize: undefined,
    darkTheme: false,
  }),
  computed: {
    ...mapGetters([
      'theme',
      'fontSize',
    ]),
  },
  watch: {
    darkTheme(value) {
      this.$store.dispatch('setTheme', value);
      this.$vuetify.theme.dark = value;
    },
    theme: {
      handler(value) {
        if (value === 'true') {
          this.darkTheme = true;
        }
      },
      immediate: true,
    },
    fontSize: {
      handler(id) {
        this.selectedFontSize = this.fontSizes.find(element => (
          element.id === Number(id)
        ));
      },
      immediate: true,
    },
    selectedFontSize: {
      handler(data) {
        this.$root.$el.ownerDocument.getElementsByTagName('html')[0].style.cssText = `
          font-size: ${data.value};
        `;
      },
      immediate: true,
    },
  },
  methods: {
    closeDialogSettings() {
      this.dialogSettings = false;
    },
    setFontSize() {
      const data = this.selectedFontSize;
      this.$store.dispatch('setFontSize', `${data.id}`);
    },
  },
});
</script>
