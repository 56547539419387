import RepositoryFactory from '@/api/RepositoryFactory';
import USER_PERMISSIONS from '@/constants/userPermissions';

const ScheduleRepository = RepositoryFactory.get('schedule');

const state = {
  userData: {
    permissions: [],
  },
};

const getters = {
  getUserData: (thisState: any) => thisState.userData,

  canAddSchedule: (thisState: any) => (
    thisState.userData.permissions.includes(
      USER_PERMISSIONS.can_add_schedule,
    )
  ),
  canViewSchedule: (thisState: any) => (
    thisState.userData.permissions.includes(
      USER_PERMISSIONS.can_view_schedule,
    )
  ),
  canChangeSchedule: (thisState: any) => (
    thisState.userData.permissions.includes(
      USER_PERMISSIONS.can_change_schedule,
    )
  ),
  canDeleteSchedule: (thisState: any) => (
    thisState.userData.permissions.includes(
      USER_PERMISSIONS.can_delete_schedule,
    )
  ),
};

const actions = {
  async setUserData({ commit }: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ScheduleRepository.getPermissions();
        const permissions = response.data;
        const userData = {
          permissions,
        };

        commit('setUserData', userData);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  },
  resetUserData({ commit }: any) {
    commit('resetUserData');
  },
};

const mutations = {
  setUserData: (thisState: any, userData: any) => {
    thisState.userData = userData;
  },
  resetUserData: (thisState: any) => {
    thisState.userData = {
      permissions: [],
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
