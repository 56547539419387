




























import Vue from 'vue';
import { Speciality } from 'typings/interface/speciality';
import RepositoryFactory from '@/api/RepositoryFactory';

const SpecialitiesRepository = RepositoryFactory.get('specialities');

export default Vue.extend({
  props: {
    itemValue: {
      type: Number,
      default: null,
    },
    faculty: {
      type: Number,
      default: undefined,
    },
    educationForm: {
      type: Number,
      default: undefined,
    },
  },
  data:() => ({
    specialities: [] as Array<Speciality>,
    isLoadingSpecialities: false,
    selectedSpeciality: null as (null | number),
    page: 1,
  }),
  computed: {
    menuProps() {
      return {
        'contentClass': 'included',
        'max-width': 280,
      };
    },
    mdAndDown() {
      return (this.$vuetify as any).breakpoint.mdAndDown;
    },
    facultyIsEmpty() {
      return !this.faculty;
    },
    educationFormIsEmpty() {
      return !this.educationForm;
    },
  },
  watch: {
    itemValue: {
      handler(value: number) {
        this.selectedSpeciality = value;
      },
      immediate: true,
    },
    faculty: {
      handler(value: number) {
        if (value && this.educationForm) {
          this.getSpecialities();
        }
      },
      immediate: true,
    },
    educationForm: {
      handler(value: number) {
        if (value && this.faculty) {
          this.getSpecialities();
        }
      },
    },
  },
  methods: {
    async getSpecialities() {
      this.isLoadingSpecialities = true;
      const queryParams = {
        faculty: this.faculty,
        education_form: this.educationForm,
        page: this.page,
      };
      const response = await SpecialitiesRepository.get(queryParams);
      this.specialities = response.data.results;
      this.isLoadingSpecialities = false;
    },
    selected() {
      this.$emit('selected', this.selectedSpeciality);
    },
  },
});
