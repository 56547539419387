























































// Imports
import Vue from 'vue';
import AUTH from '@/store/actions/auth';

export default Vue.extend({
  data:() => ({
    username: '' as string,
    usernameRules: [
      (v: string) => !!v || 'Логин обязательное поле',
    ],
    password: '' as string,
    passwordRules: [
      (v: string) => !!v || 'Пароль обязательное поле',
    ],
    valid: true,
    alertAuth: false,
    alertErrorMessage: '' as string,
  }),
  methods: {
    validate() {
      const vLoginForm: any = this.$refs.loginForm;

      if (vLoginForm.validate()) {
        this.login();
      }
    },
    login() {
      const { password, username } = this;
      this.alertErrorMessage = '';

      this.$store.dispatch(AUTH.REQUEST, { password, username })
        .then(() => {
          this.$router.push('/');
        }, (error) => {
          this.alertAuth = true;
          [this.alertErrorMessage] = error.data.non_field_errors;
        });
    },
  },
});
