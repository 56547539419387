






























import Vue from 'vue';
import AlertGroup from '@/components/AlertGroup/index.vue';
import ConsultationCard from '@/components/ConsultationCard/index.vue';
import CHAIRS from '@/dictionaries/chairs';

export default Vue.extend({
  components: {
    AlertGroup,
    ConsultationCard,
  },
  data:() => ({
    snackbarInstance: {
      active: false,
    },
    snackbarQueue: [],
    chairs: CHAIRS,
  }),
  computed: {
    chairIsEmptyText() {
      return `
        В данный момент график консультаций по данной кафедре
        отсутствует
      `;
    },
  },
  methods: {
    setNotify(chairKey: number) {
      const snackbarWithChairKey = this.snackbarQueue.some((snackbar: any) => (
        snackbar.chairKey === chairKey
      ));

      if (!snackbarWithChairKey) {
        const newSnackbar = Object.assign({}, this.snackbarInstance, {
          id: this.snackbarQueue.length + 1,
          chairKey,
          active: true,
          timeout: 3000,
        });

        setTimeout(() => {
          newSnackbar.active = false;
          this.deleteSnackbar(newSnackbar.id);
        }, newSnackbar.timeout);

        this.snackbarQueue.push(newSnackbar as never);
      }
    },
    deleteSnackbar(id: number) {
      const snackbarIndex = this.snackbarQueue.findIndex((snackbar: any) => (
        snackbar.id === id
      ));
      this.snackbarQueue.splice(snackbarIndex, 1);
    },
  },
});
