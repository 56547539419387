





























import Vue from 'vue';
import { Group } from 'typings/interface/group';

export default Vue.extend({
  props: {
    detailRouteName: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      default: [] as Array<Group>,
    },
  },
});
