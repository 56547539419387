import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/authorization/Login.vue';
import Consultations from '@/views/consultations/index.vue';
import Groups from '@/views/groups/Base.vue';
import GroupsDetail from '@/views/groups/Detail.vue';
import AddSchedule from '@/views/schedule/Add.vue';
import NotFound from '@/views/NotFound.vue';
import USER_PERMISSIONS from '@/constants/userPermissions';
import store from '@/store';

Vue.use(Router);

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!store.getters.isAuthenticated) {
    next();
  } else {
    next('/');
  }
};

const ifAuthenticated = (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated) {
    const userData = store.getters.getUserData;
    const { accessList } = to.meta;

    if (userData?.permissions.includes(...accessList)) {
      next();
    } else {
      next({ name: 'not-found' });
    }
  } else {
    next('/login');
  }
};

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: '/groups',
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '/consultations',
      name: 'consultations',
      component: Consultations,
    },
    {
      path: '/groups',
      name: 'groups',
      component: Groups,
    },
    {
      path: '/group/:groupname',
      name: 'group',
      component: GroupsDetail,
    },
    {
      path: '/add-schedule',
      name: 'add-schedule',
      props: true,
      component: AddSchedule,
      beforeEnter: ifAuthenticated,
      meta: {
        accessList: [
          USER_PERMISSIONS.can_add_schedule,
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: ifNotAuthenticated,
    },
  ],
});
