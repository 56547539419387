




































































































































































































import { Group } from 'typings/interface/group/index';
import { Schedule, ScheduleTypes } from 'typings/interface/schedule/index';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import SCHEDULE_TYPES from '@/constants/scheduleTypes';
import AlertGroup from '@/components/AlertGroup/index.vue';
import FormEditingSchedule from '@/components/FormEditingSchedule/index.vue';
import RepositoryFactory from '@/api/RepositoryFactory';
import { BASE_URL } from '@/api/Repository';

const GroupsRepository = RepositoryFactory.get('groups');
const ScheduleRepository = RepositoryFactory.get('schedule');
const AttachmentsRepository = RepositoryFactory.get('attachments');
const BlobRepository = RepositoryFactory.get('blob');

export default Vue.extend({
  components: {
    FormEditingSchedule,
    AlertGroup,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data:() => ({
    group: {} as Group,
    isLoadingGroup: false,
    isLoadingAttachedGroup: false,
    schedules: [] as Array<Schedule>,
    selectedSchedule: undefined as (undefined | number),
    selectedScheduleObj: undefined as (undefined | Schedule),
    attachedGroups: [] as Array<Group>,
    isLoadingSchedule: false,
    isDeleteSchedule: false,
    dialogRemoveSchedule: false,
    dialogUpdateSchedule: false,
    cantLoadEditingScheduleForm: false,
    editingFormData: undefined as any,
    isLoadingAttachments: false,
    scheduleTypes: SCHEDULE_TYPES,
    snackbarQueue: [] as Array<any>,
    snackbarFormDefault: {
      active: false,
      type: undefined,
      outlined: false,
      action: false,
      actionColor: 'red',
      timeout: null as (null | number),
      text: null as (null | string),
      groups: [] as Array<any>,
    },
    BASE_URL,
  }),
  computed: {
    ...mapGetters({
      canChangeSchedule: 'canChangeSchedule',
      canDeleteSchedule: 'canDeleteSchedule',
    }),
    mdAndDown() {
      return (this.$vuetify as any).breakpoint.mdAndDown;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    canWorkWithSchedule() {
      return (
        this.isAuthenticated
        && (
          this.canChangeSchedule
          || this.canDeleteSchedule
        )
      );
    },
  },
  watch: {
    name: {
      handler() {
        this.getGroup();
      },
      immediate: true,
    },
    group(item: Group) {
      if (this.isAuthenticated) {
        this.getScheduleAddButton();
      }

      this.getSchedules(item.id);
    },
    dialogRemoveSchedule(value: Boolean) {
      if (!value) {
        this.selectedSchedule = undefined;
      } else {
        this.getAttachedGroups();
      }
    },
    dialogUpdateSchedule(value: Boolean) {
      if (!value) {
        this.selectedSchedule = undefined;
        this.selectedScheduleObj = undefined;
      } else {
        if (this.selectedScheduleObj) {
          this.selectedSchedule = this.selectedScheduleObj.id;
        }
        this.getAttachedGroups();
      }
    },
    snackbarQueue(items: any) {
      const lastItem = items[items.length - 1];

      if (lastItem) {
        if (lastItem.active && lastItem.timeout) {
          setTimeout(() => {
            items.splice(lastItem.index, 1);
          }, lastItem.timeout);
        }
      }
    },
  },
  methods: {
    async getGroup() {
      this.isLoadingGroup = true;
      const queryParams = { name: this.name };
      const response = await GroupsRepository.get(queryParams);
      if (response.data.results.length > 0) {
        [this.group] = response.data.results;
      } else {
        this.$router.push({ name: 'not-found' });
      }
      this.isLoadingGroup = false;
    },
    async getSchedules(id: number) {
      this.isLoadingSchedule = true;
      const response = await ScheduleRepository.get(id);
      this.schedules = response.data.results;
      this.isLoadingSchedule = false;
    },
    async deleteSchedule() {
      this.isDeleteSchedule = true;
      const id = this.selectedSchedule;
      await ScheduleRepository.delete(id);
      this.dialogRemoveSchedule = false;
      this.updateSchedules();
      this.isDeleteSchedule = false;
    },
    async getAttachedGroups() {
      this.isLoadingAttachedGroup = true;
      const queryParams = { schedule: this.selectedSchedule };
      const response = await GroupsRepository.get(queryParams);
      this.attachedGroups = response.data.results;
      this.isLoadingAttachedGroup = false;
    },
    async downloadFile(url: string) {
      try {
        const resource = url.split('/').slice(3).join('/');
        const response = await BlobRepository.get(resource);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        this.setSnackErrorMessage(error);
      }
    },
    dialogDeleteSchedule(id: number) {
      this.dialogRemoveSchedule = true;
      this.selectedSchedule = id;
    },
    dialogEditSchedule(schedule: Schedule) {
      this.dialogUpdateSchedule = true;
      this.selectedScheduleObj = schedule;
    },
    getScheduleDatePeriodText(schedule: Schedule) {
      return `
        С ${new Date(schedule.date_from).toLocaleDateString()}
        по ${new Date(schedule.date_to).toLocaleDateString()}
      `;
    },
    getScheduleTitle(schedule: Schedule) {
      let title;

      if (schedule.type) {
        const typeId: (keyof ScheduleTypes) = schedule.type.id;
        title = this.scheduleTypes[typeId];
      } else {
        title = schedule.description;
      }

      return title;
    },
    updateSchedules() {
      const currentGroup = this.group;
      this.getSchedules(currentGroup.id);
    },
    getScheduleAddButton() {
      const currentGroup = this.group;
      this.$emit('getScheduleAddButton', currentGroup);
    },
    onCantLoadForm(value: boolean) {
      this.cantLoadEditingScheduleForm = value;
    },
    onLoadFormData(data: any) {
      this.editingFormData = data;
    },
    async removeAttachments() {
      await Promise.all(this.editingFormData.attachmentsForRemove.map(
        async (id: number) => {
          await AttachmentsRepository.delete(id);
        },
      ));
    },
    async loadAttachments() {
      this.isLoadingAttachments = true;
      await Promise.all(this.editingFormData.selectedAttachments.map(
        async (attachment: any) => {
          const formData = new FormData();
          formData.append('file', attachment);

          const response = await AttachmentsRepository.set(formData);
          this.editingFormData.loadedAttachments.push(response.data.id);
        },
      ));
      this.isLoadingAttachments = false;
    },
    loadSchedules() {
      if (!this.cantLoadEditingScheduleForm) {
        this.removeAttachments().then(() => {
          this.setSnackSuccessMessage({
            active: true,
            text: 'Старые файлы удалены',
            type: 'warning',
            timeout: 3000,
          });
          this.loadAttachments().then(
            () => {
              this.setSnackSuccessMessage({
                active: true,
                text: 'Файлы расписания успешно загружены',
                type: 'success',
                timeout: 3000,
              });
              this.updateSchedule();
            },
            (error) => {
              this.setSnackErrorMessage(error);
            },
          );
        });
      }
    },
    async updateSchedule() {
      this.isLoadingSchedule = true;
      const id = this.selectedSchedule;
      const queryParams = this.editingFormData.query;
      queryParams.attachments = this.editingFormData.loadedAttachments;

      try {
        await ScheduleRepository.put(id, queryParams);
        this.dialogUpdateSchedule = false;
        this.updateSchedules();
        this.setSnackSuccessMessage({
          active: true,
          text: 'Расписание успешно обновлено',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.setSnackErrorMessage(error);
      }
      this.isLoadingSchedule = false;
    },
    setSnackErrorMessage(error: any) {
      const errorsObj = error.data;
      const errors = [] as Array<any>;

      Object.keys(errorsObj).forEach((key) => {
        errors.push(`${key}: ${errorsObj[key]}`);
      });

      const snackbar = Object.assign({}, this.snackbarFormDefault, {
        active: true,
        text: errors.join(' и '),
        type: 'error',
        action: true,
      });
      this.snackbarQueue.push(snackbar);
    },
    setSnackSuccessMessage(snackbarSettings: any) {
      const snackbar = Object.assign({}, this.snackbarFormDefault, snackbarSettings);
      this.snackbarQueue.push(snackbar);
    },
    closeSnackbar(snackbar: any) {
      snackbar.active = false;
      this.snackbarQueue.splice(snackbar.index, 1);
    },
    linkToAttachment(scheduleId: Number, attachmentId: Number) {
      return `${BASE_URL}/schedule/attachment/${scheduleId}/${attachmentId}`;
    },
  },
});
