












import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data:() => ({
    description: null as (null | string),
    messages: ['Если тип расписания не подходит, введите описание'],
  }),
  watch: {
    value: {
      handler(value: (null | string)) {
        this.description = value;
      },
      immediate: true,
    },
    description(value: (null | string)) {
      this.$emit('update:value', value);
    },
  },
});
