



























import Vue from 'vue';
import EDUCATION_FORMS from '@/dictionaries/formsEducations';

export default Vue.extend({
  props: {
    itemValue: {
      type: Number,
      default: null,
    },
    faculty: {
      type: Number,
      default: undefined,
    },
  },
  data:() => ({
    educationForms: EDUCATION_FORMS,
    selectedEducationForm: null as (null | number),
  }),
  computed: {
    menuProps() {
      return {
        'contentClass': 'included',
        'max-width': 280,
      };
    },
    mdAndDown() {
      return (this.$vuetify as any).breakpoint.mdAndDown;
    },
    facultyIsEmpty() {
      return !this.faculty;
    },
  },
  watch: {
    itemValue: {
      handler(value: number) {
        this.selectedEducationForm = value;
      },
      immediate: true,
    },
  },
  methods: {
    selected() {
      this.$emit('selected', this.selectedEducationForm);
    },
  },
});
