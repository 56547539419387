




































import Vue from 'vue';

export default Vue.extend({
  props: {
    max: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data:() => ({
    modalStartDatePicker: false,
    selectedStartDate: null as (null | string),
  }),
  watch: {
    value: {
      handler(value: (null | string)) {
        this.selectedStartDate = value;
      },
      immediate: true,
    },
    selectedStartDate(value: (null | string)) {
      this.$emit('update:value', value);
    },
  },
  methods: {
    saveDate(selectedStartDate: string) {
      const vDialogDatePicker: any = this.$refs.dialogStartDatePicker;
      vDialogDatePicker.save(selectedStartDate);
    },
  },
});
