












import Vue from 'vue';

export default Vue.extend({
  model: {
    prop: 'value',
    event: 'click',
  },
  props: {
    width: {
      type: [Number, String],
      default: 304,
    },
    value: {
      type: Boolean,
    },
  },
  computed: {
    cssVars() {
      return {
        '--side-sheet-width': `${this.width}px`,
      };
    },
    classes() {
      return {
        'side-sheet--opened': this.value,
        'side-sheet--fixed': (this.$vuetify as any).breakpoint.mdAndDown,
        'side-sheet--absolute': (this.$vuetify as any).breakpoint.mdAndUp,
      };
    },
    mdAndUp() {
      return (this.$vuetify as any).breakpoint.mdAndUp;
    },
    mdAndDown() {
      return (this.$vuetify as any).breakpoint.mdAndDown;
    },
  },
  watch: {
    value: {
      handler(value: boolean) {
        const [vApp]: any = this.$root.$children;
        const vSideSheetOverlay: any = vApp.$refs.sideSheetOverlay;

        if (this.mdAndDown) {
          vSideSheetOverlay.isActive = value;
        } else {
          vSideSheetOverlay.isActive = false;
        }
      },
    },
    mdAndDown(value: boolean) {
      if (value && this.value) {
        this.closeSheet();
      }
    },
    mdAndUp(value: boolean) {
      if (value && this.value) {
        this.closeSheet();
      }
    },
  },
  methods: {
    closeSheet() {
      this.$emit('click', false);
    },
    onClickOutside() {
      if (this.mdAndDown && this.value) {
        this.closeSheet();
      }
    },
    include() {
      const allIncluded = document.querySelectorAll('.included');
      return Array.prototype.slice.call(allIncluded);
    },
  },
});
