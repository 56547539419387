











































import Vue from 'vue';
import { Route } from 'vue-router';
import SelectFaculty from '@/components/SelectFaculty/index.vue';
import SelectSpeciality from '@/components/SelectSpeciality/index.vue';
import SelectEducationForm from '@/components/SelectEducationForm/index.vue';
import SelectCourse from '@/components/SelectCourse/index.vue';

export default Vue.extend({
  components: {
    SelectFaculty,
    SelectSpeciality,
    SelectEducationForm,
    SelectCourse,
  },
  props: {
    routeName: {
      type: String,
      required: true,
    },
  },
  data:() => ({
    filterQueryProps: {} as any,
    selectedFaculty: null as (null | number),
    selectedSpeciality: null as (null | number),
    selectedEducationForm: null as (null | number),
    selectedCourse: null as (null | number),
  }),
  watch: {
    $route: {
      handler(route: Route) {
        const queryParams = route.query;
        const newFilterQueryProps = {} as any;

        if (queryParams.speciality__faculty) {
          newFilterQueryProps.speciality__faculty = Number(queryParams.speciality__faculty);
        }
        if (queryParams.speciality__education_form) {
          newFilterQueryProps.speciality__education_form = Number(queryParams.speciality__education_form);
        }
        if (queryParams.speciality) {
          newFilterQueryProps.speciality = Number(queryParams.speciality);
        }
        if (queryParams.course) {
          newFilterQueryProps.course = Number(queryParams.course);
        }

        this.filterQueryProps = newFilterQueryProps;
      },
      immediate: true,
    },
    filterQueryProps: {
      handler(value: any) {
        this.selectedFaculty = value.speciality__faculty;
        this.selectedEducationForm = value.speciality__education_form;
        this.selectedSpeciality = value.speciality;
        this.selectedCourse = value.course;

        this.selected(value);
      },
      immediate: true,
    },
  },
  methods: {
    onSelectedFaculty(selected: (null | number)) {
      let filterQueryProps;

      if (selected === null) {
        filterQueryProps = Object.assign(this.filterQueryProps, {
          speciality: undefined,
          speciality__faculty: undefined,
          speciality__education_form: undefined,
          course: undefined,
        });
      } else {
        filterQueryProps = Object.assign(this.filterQueryProps, {
          speciality: undefined,
          speciality__faculty: selected,
          speciality__education_form: undefined,
          course: undefined,
        });
      }

      this.$store.dispatch('resetGroups');
      this.$router.push({ name: this.routeName, query: filterQueryProps });
    },
    onSelectedEducationForm(selected: (null | number)) {
      let filterQueryProps;

      if (selected === null) {
        filterQueryProps = Object.assign(this.filterQueryProps, {
          speciality: undefined,
          speciality__education_form: undefined,
          course: undefined,
        });
      } else {
        filterQueryProps = Object.assign(this.filterQueryProps, {
          speciality: undefined,
          speciality__education_form: selected,
          course: undefined,
        });
      }

      this.$store.dispatch('resetGroups');
      this.$router.push({ name: this.routeName, query: filterQueryProps });
    },
    onSelectedSpeciality(selected: (null | number)) {
      let filterQueryProps;

      if (selected === null) {
        filterQueryProps = Object.assign(this.filterQueryProps, {
          speciality: undefined,
          course: undefined,
        });
      } else {
        filterQueryProps = Object.assign(this.filterQueryProps, {
          speciality: selected,
          course: undefined,
        });
      }

      this.$store.dispatch('resetGroups');
      this.$router.push({ name: this.routeName, query: filterQueryProps });
    },
    onSelectedCourse(selected: (null | number)) {
      let filterQueryProps;

      if (selected === null) {
        filterQueryProps = Object.assign(this.filterQueryProps, {
          course: undefined,
        });
      } else {
        filterQueryProps = Object.assign(this.filterQueryProps, {
          course: selected,
        });
      }

      this.$store.dispatch('resetGroups');
      this.$router.push({ name: this.routeName, query: filterQueryProps });
    },
    selected(query: any) {
      this.$emit('selected', query);
    },
    showGroup() {
      this.$emit('show-groups');
    },
  },
});
