import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import settings from './modules/settings';
import groups from './modules/groups';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    settings,
    groups,
    user,
  },
});
