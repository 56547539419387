




import Vue from 'vue';
import CardGroups from '@/components/CardGroups/index.vue';

export default Vue.extend({
  components: {
    CardGroups,
  },
  data:() => ({
    groupName: '' as string,
  }),
  created() {
    const route = this.$route;
    this.groupName = route.params.groupname;
  },
});
