




















































import Vue from 'vue';
import { Group } from 'typings/interface/group';
import { Attachment } from 'typings/interface/attachment';
import { Schedule } from 'typings/interface/schedule';
import SelectDateStartSchedule from '@/components/SelectDateStartSchedule/index.vue';
import SelectDateEndSchedule from '@/components/SelectDateEndSchedule/index.vue';
import SelectAttachments from '@/components/SelectAttachments/index.vue';
import SelectScheduleType from '@/components/SelectScheduleType/index.vue';
import TextFieldScheduleDescription from '@/components/TextFieldScheduleDescription/index.vue';

export default Vue.extend({
  components: {
    SelectDateStartSchedule,
    SelectDateEndSchedule,
    SelectAttachments,
    SelectScheduleType,
    TextFieldScheduleDescription,
  },
  props: {
    schedule: {
      type: Object,
      default: undefined as (undefined | Schedule),
    },
  },
  data:() => ({
    selectedStartDateSchedule: null as (null | string),
    selectedEndDateSchedule: null as (null | string),
    selectedScheduleType: null as (null | number),
    introducedScheduleDescription: null as (null | string),
    selectedScheduleAttachments: [] as Array<Attachment>,
    selectedGroups: [] as Array<Group>,
    scheduleAttachments: [] as Array<Attachment>,
    scheduleAttachmentsBase: [] as Array<Attachment>,
    attachmentsForRemove: [] as Array<number>,
    loadedAttachments: [] as Array<Number>,
    isLoadingAttachments: false,
    isLoadingSchedule: false,
  }),
  computed: {
    dateStartIsNotSelected() {
      return !this.selectedStartDateSchedule;
    },
    datesIsNotSelected() {
      return !(this.selectedStartDateSchedule && this.selectedEndDateSchedule);
    },
    typeOrDescriptionIsNotIntroduced() {
      return !(this.selectedScheduleType !== null || this.introducedScheduleDescription);
    },
    cantLoadSchedules() {
      return (
        !(this.selectedStartDateSchedule && this.selectedEndDateSchedule)
        || !(this.selectedScheduleType !== null || this.introducedScheduleDescription)
        || this.selectedGroups.length <= 0
        || (
          (this.selectedScheduleAttachments.length > 0 && this.scheduleAttachments.length > 0)
          || (this.selectedScheduleAttachments.length <= 0 && this.scheduleAttachments.length <= 0)
        )
      );
    },
  },
  watch: {
    schedule: {
      handler(schedule: (undefined | Schedule)) {
        if (schedule) {
          this.selectedStartDateSchedule = schedule.date_from;
          this.selectedEndDateSchedule = schedule.date_to;
          this.introducedScheduleDescription = schedule.description;
          this.scheduleAttachmentsBase = schedule.attachments;
          this.scheduleAttachments = schedule.attachments;
          this.selectedGroups = schedule.groups;
          this.attachmentsForRemove = [];

          if (schedule.type) {
            this.selectedScheduleType = schedule.type.id;
          }
        } else {
          const vFormEditingSchedule: any = this.$refs.formEditingSchedule;
          vFormEditingSchedule.reset();
        }
      },
      immediate: true,
    },
    scheduleAttachments: {
      handler(attachments: Array<Attachment>) {
        this.loadedAttachments = attachments.map(attachment => attachment.id);
      },
      immediate: true,
    },
    cantLoadSchedules: {
      handler() {
        this.cantLoadForm();
        this.loadFormData();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getAttachmentFileName(attachment: Attachment) {
      return decodeURI(attachment.file).split('/').slice(-1).toString();
    },
    removeAttachment(id: number) {
      this.scheduleAttachments = this.scheduleAttachments.filter(attachment => attachment.id !== id);
      this.attachmentsForRemove.push(id);
    },
    cantLoadForm() {
      this.$emit('cantLoadForm', this.cantLoadSchedules);
    },
    loadFormData() {
      const groupList = this.selectedGroups.map(group => group.id);
      let formDataQuery = {
        date_from: this.selectedStartDateSchedule,
        date_to: this.selectedEndDateSchedule,
        groups: groupList,
      };

      if (this.selectedScheduleType !== null) {
        formDataQuery = Object.assign(formDataQuery, {
          type: this.selectedScheduleType,
        });
      }

      if (this.introducedScheduleDescription) {
        formDataQuery = Object.assign(formDataQuery, {
          description: this.introducedScheduleDescription,
        });
      }

      const formData = {
        query: formDataQuery,
        selectedAttachments: this.selectedScheduleAttachments,
        loadedAttachments: this.loadedAttachments,
        attachmentsForRemove: this.attachmentsForRemove,
      };

      this.$emit('loadFormData', formData);
    },
  },
});
