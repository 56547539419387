const state = {
  groups: localStorage.getItem('groups') || '[]',
};

const getters = {
  groups: (thisState: any) => JSON.parse(thisState.groups),
};

const actions = {
  setGroups({ commit }: any, groups: any) {
    commit('setGroups', groups);
  },
  resetGroups({ commit }: any) {
    commit('resetGroups');
  },
};

const mutations = {
  setGroups(thisState: any, groups: any) {
    thisState.groups = JSON.stringify(groups);
  },
  resetGroups(thisState: any) {
    thisState.groups = '[]';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
