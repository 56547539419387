import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/src/locale/en';
import ru from 'vuetify/src/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, ru },
    current: 'ru',
  },
  theme: {
    themes: {
      light: {
        primary: '#6100ED',
        secondary: '#EEEEEE',
      },
      dark: {
        secondary: '#212121',
      },
    },
  },
});
