import Repository from '../Repository';

const resource = '/schedule';

export default {
  get(id: number) {
    return Repository.get(`${resource}/?groups=${id}`);
  },
  set(data: any) {
    return Repository.post(`${resource}/schedule-set/`, data);
  },
  delete(id: number) {
    return Repository.delete(`${resource}/schedule-set/${id}`);
  },
  put(id: number, data: any) {
    return Repository.put(`${resource}/schedule-set/${id}/`, data);
  },
  patch(id: number, data: any) {
    return Repository.patch(`${resource}/schedule-set/${id}/`, data);
  },
  getPermissions() {
    return Repository.get(`${resource}/permissions`);
  },
};
