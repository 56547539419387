








import Vue from 'vue';

export default Vue.extend({
  props: {
    right: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  data:() => ({
  }),
  computed: {
    styles() {
      return {
        'padding-top': `
          ${(this.$vuetify as any).application.bar
          + (this.$vuetify as any).application.top}px
        `,
        'padding-bottom': `
          ${(this.$vuetify as any).application.bottom
          + (this.$vuetify as any).application.footer
          + (this.$vuetify as any).application.insetFooter}px
        `,
      };
    },
    classList() {
      return {
        'v-alert-group--content-right': this.right,
        'v-alert-group--content-left': this.left,
        'v-alert-group--content-center': this.center,
      };
    },
  },
});
