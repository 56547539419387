
















































import Vue from 'vue';
import _ from 'lodash';
import { Group } from 'typings/interface/group';
import SideSheet from '@/components/SideSheet/index.vue';
import FilterGroups from '@/components/FilterGroups/index.vue';
import AutocompleteGroups from '@/components/AutocompleteGroups/index.vue';
import CardManual from '@/components/CardManual/index.vue';
import CardListGroups from '@/components/CardListGroups/index.vue';
import FormAddingSchedule from '@/components/FormAddingSchedule/index.vue';

export default Vue.extend({
  components: {
    SideSheet,
    FilterGroups,
    AutocompleteGroups,
    CardManual,
    CardListGroups,
    FormAddingSchedule,
  },
  props: {
    group: {
      type: Object,
      default: undefined,
    },
  },
  data:() => ({
    filterQueryParams: {} as any,
    selectedGroupsAutocomplete: [] as Array<Group>,
    groupsFilterAutocomplete: false,
    filterRouteName: 'add-schedule',
  }),
  computed: {
    groupsIsNotEmpty() {
      return !this.selectedGroupsAutocomplete.length;
    },
  },
  methods: {
    onSelectedFilters(selected: any) {
      this.filterQueryParams = selected;
    },
    onSelectedGroups(selected: any) {
      this.selectedGroupsAutocomplete = selected;
    },
    onFilterIsActivated(isActivated: boolean) {
      this.groupsFilterAutocomplete = isActivated;
    },
  },
});
