































































































































import Vue from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { Group } from 'typings/interface/group';
import Repository from '@/api/Repository';
import LogoutButton from '@/components/ButtonLogout/index.vue';
import ButtonVisuallyImpaired from '@/components/ButtonVisuallyImpaired/index.vue';
import AUTH from './store/actions/auth';

export default Vue.extend({
  name: 'App',
  components: {
    LogoutButton,
    ButtonVisuallyImpaired,
  },
  data: () => ({
    isGroupsLoading: false,
    drawer: false,
    group: null,
    currentGroup: {} as Group,
    scheduleAddButton: false,
    floatButtonHeight: 0,
  }),
  computed: {
    ...mapGetters({
      canAddSchedule: 'canAddSchedule',
    }),
    cssFloatButtonVars() {
      return {
        '--float-button-height': `${this.floatButtonHeight}px`,
      };
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  watch: {
    $route() {
      this.scheduleAddButton = false;
    },
    scheduleAddButton(value: boolean) {
      if (value) {
        this.$nextTick(() => {
          const vFloatButton: any = this.$refs.addScheduleButton;
          this.floatButtonHeight = vFloatButton.$el.clientHeight;
        });
      }
    },
  },
  created() {
    this.autoLogout();

    // Getting user permissions
    if (this.isAuthenticated) {
      this.$store.dispatch('setUserData');
    }
  },
  methods: {
    async autoLogout() {
      Repository.interceptors.response.use(
        (response: any) => response,
        (error: any) => {
          if (error.config && error.response && error.response.status === 401) {
            this.$store.dispatch(AUTH.LOGOUT, error);
            this.$router.push('/login');
          }
          return Promise.reject(error.response);
        },
      );
    },
    onGetScheduleAddButton(group: Group) {
      this.currentGroup = group;
      this.scheduleAddButton = true;
    },
  },
});
