































































import Vue from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { Group } from 'typings/interface/group/index';
import RepositoryFactory from '@/api/RepositoryFactory';

const GroupsRepository = RepositoryFactory.get('groups');

export default Vue.extend({
  props: {
    selectedGroup: {
      type: Object,
      default: undefined,
    },
    noFilterAppend: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      required: true,
    },
    filterValue: {
      type: Boolean,
      default: false,
    },
    filterParams: {
      type: Object,
      default: {} as any,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  data:() => ({
    ...mapGetters([
      'groups',
    ]),
    selectedGroups: [] as Array<Group>,
    groups: [] as Array<Group>,
    isLoadingGroups: false,
    debouncedGroups: null as any,
    groupSearch: null as (null | string),
    groupsQueryParams: {
      name: undefined as (undefined | string),
      page: 1 as number,
    },
    isLastPageGroups: false,
    autocompleteTopPosition: 0,
  }),
  computed: {
    hasQueryProps() {
      return Object.values(
        this.filterParams,
      ).some(item => Boolean(item));
    },
    isNotEmptyGroups() {
      return !!this.groups.length;
    },
    menuProps() {
      const defaultProps = {
        closeOnClick: false,
        closeOnContentClick: true,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 304,
        top: false,
      };

      if (this.$vuetify.breakpoint.mdAndDown) {
        defaultProps.maxHeight = this.autocompleteTopPosition;
        defaultProps.top = true;
      }

      return defaultProps;
    },
    mdAndDown() {
      return (this.$vuetify as any).breakpoint.mdAndDown;
    },
  },
  watch: {
    selectedGroup: {
      handler(group: Group) {
        if (group !== undefined) {
          const groups = this.selectedGroups;

          this.selectedGroups.push(group);
          this.selected(groups);
        }
      },
      immediate: true,
    },
    groupSearch(value: any) {
      this.groupsQueryParams.name = value;
      this.debouncedGroups();
    },
    selectedGroups(groups: Array<Group>) {
      this.deactivateMenuAutocomplete();
      this.selected(groups);

      if (groups.length > 0) {
        this.groupSearch = null;
      }
    },
    filterParams() {
      this.groups = [];
      this.groupsQueryParams.page = 1;
      this.selectedGroups = this.$store.getters.groups;
      const vAutocomplete: any = this.$refs.groupsAutocomplete;
      vAutocomplete.$refs.menu.updateDimensions();
      this.getGroups();
    },
  },
  mounted() {
    const vAutocomplete: any = this.$refs.groupsAutocomplete;
    const vAutocompleteBounds = vAutocomplete.$el.getBoundingClientRect();
    this.autocompleteTopPosition = vAutocompleteBounds.top;
  },
  created() {
    this.debouncedGroups = _.debounce(() => {
      this.groupsQueryParams.page = 1;
      this.getGroups();
    }, 300);
  },
  methods: {
    onIntersectGroups(entries: any) {
      if (entries[0].isIntersecting) {
        this.getNextGroups();
      }
    },
    deactivateMenuAutocomplete() {
      const vAutocomplete: any = this.$refs.groupsAutocomplete;
      const sideSheetElement: any = document.querySelector('.side-sheet');
      vAutocomplete.blur();
      sideSheetElement.click();
    },
    toggleGroupsFilter() {
      this.deactivateMenuAutocomplete();
      this.filterIsActivated(!this.filterValue);
    },
    async getNextGroups() {
      this.isLoadingGroups = true;
      this.groupsQueryParams.page += 1;
      const queryParams = Object.assign({} as any, this.groupsQueryParams, this.filterParams);
      const response = await GroupsRepository.get(queryParams);
      Array.from(response.data.results).forEach((item: any) => {
        this.groups.push(item);
      });

      if (response.data.next === null) {
        this.isLastPageGroups = true;
      } else {
        this.isLastPageGroups = false;
      }

      this.isLoadingGroups = false;
    },
    async getGroups() {
      this.isLoadingGroups = true;

      try {
        const queryParams = Object.assign({} as any, this.groupsQueryParams, this.filterParams);
        const response = await GroupsRepository.get(queryParams);
        this.groups = response.data.results;
        this.groups = this.groups.concat(this.selectedGroups);

        if (response.data.next === null) {
          this.isLastPageGroups = true;
        } else {
          this.isLastPageGroups = false;
        }
      } catch {
        const queryParams = Object.assign({} as any, { name: undefined });
        this.$router.replace({ name: this.routeName, query: queryParams });
      }

      this.isLoadingGroups = false;
    },
    selected(groups: Array<Group>) {
      this.$emit('selected', groups);
      this.$store.dispatch('setGroups', groups);
    },
    filterIsActivated(isActivated: boolean) {
      this.$emit('filterIsActivated', isActivated);
    },
  },
});
