




































import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data:() => ({
    modalDatePicker: false,
    selectedDate: null as (null | string),
  }),
  watch: {
    value: {
      handler(value: (null | string)) {
        this.selectedDate = value;
      },
      immediate: true,
    },
    selectedDate(value: (null | string)) {
      this.$emit('update:value', value);
    },
  },
  methods: {
    saveDate(selectedDate: string) {
      const vDialogDatePicker: any = this.$refs.dialogDatePicker;
      vDialogDatePicker.save(selectedDate);
    },
  },
});
