export default {
  can_add_token: 'authtoken.add_token',
  can_view_token: 'authtoken.view_token',
  can_change_token: 'authtoken.change_token',
  can_delete_token: 'authtoken.delete_token',
  can_add_user: 'auth.add_user',
  can_add_group: 'auth.add_group',
  can_add_permission: 'auth.add_permission',
  can_view_user: 'auth.view_user',
  can_view_group: 'auth.view_group',
  can_view_permission: 'auth.view_permission',
  can_change_user: 'auth.change_user',
  can_change_group: 'auth.change_group',
  can_change_permission: 'auth.change_permission',
  can_delete_user: 'auth.delete_user',
  can_delete_group: 'auth.delete_group',
  can_delete_permission: 'auth.delete_permission',
  can_add_schedule: 'core.add_schedule',
  can_add_attachment: 'core.add_attachment',
  can_add_historicalschedule: 'core.add_historicalschedule',
  can_add_historicalattachment: 'core.add_historicalattachment',
  can_view_schedule: 'core.view_schedule',
  can_view_attachment: 'core.view_attachment',
  can_view_historicalschedule: 'core.view_historicalschedule',
  can_view_historicalattachment: 'core.view_historicalattachment',
  can_change_schedule: 'core.change_schedule',
  can_change_attachment: 'core.change_attachment',
  can_change_historicalschedule: 'core.change_historicalschedule',
  can_change_historicalattachment: 'core.change_historicalattachment',
  can_delete_schedule: 'core.delete_schedule',
  can_delete_attachment: 'core.delete_attachment',
  can_delete_historicalschedule: 'core.delete_historicalschedule',
  can_delete_historicalattachment: 'core.delete_historicalattachment',
  can_change_logentry: 'admin.change_logentry',
  can_add_logentry: 'admin.add_logentry',
  can_view_logentry: 'admin.view_logentry',
  can_delete_logentry: 'admin.delete_logentry',
  can_add_constance: 'database.add_constance',
  can_delete_constance: 'database.delete_constance',
  can_change_constance: 'database.change_constance',
  can_view_constance: 'database.view_constance',
  can_view_session: 'sessions.view_session',
  can_add_session: 'sessions.add_session',
  can_change_session: 'sessions.change_session',
  can_delete_session: 'sessions.delete_session',
  can_delete_contenttype: 'contenttypes.delete_contenttype',
  can_change_contenttype: 'contenttypes.change_contenttype',
  can_add_contenttype: 'contenttypes.add_contenttype',
  can_view_contenttype: 'contenttypes.view_contenttype',
  can_delete_historicalfaculty: 'hierarchy.delete_historicalfaculty',
  can_change_profile: 'hierarchy.change_profile',
  can_add_historicalprofile: 'hierarchy.add_historicalprofile',
  can_delete_academicgroup: 'hierarchy.delete_academicgroup',
  can_delete_historicalacademicgroup: 'hierarchy.delete_historicalacademicgroup',
  can_add_historicalacademicgroup: 'hierarchy.add_historicalacademicgroup',
  can_add_faculty: 'hierarchy.add_faculty',
  can_view_academicgroup: 'hierarchy.view_academicgroup',
  can_change_academicgroup: 'hierarchy.change_academicgroup',
  can_change_historicalspeciality: 'hierarchy.change_historicalspeciality',
  can_delete_profile: 'hierarchy.delete_profile',
  can_add_academicgroup: 'hierarchy.add_academicgroup',
  can_add_speciality: 'hierarchy.add_speciality',
  can_view_speciality: 'hierarchy.view_speciality',
  can_change_historicalacademicgroup: 'hierarchy.change_historicalacademicgroup',
  can_view_historicalfaculty: 'hierarchy.view_historicalfaculty',
  can_change_historicalprofile: 'hierarchy.change_historicalprofile',
  can_delete_historicalspeciality: 'hierarchy.delete_historicalspeciality',
  can_view_historicalprofile: 'hierarchy.view_historicalprofile',
  can_view_faculty: 'hierarchy.view_faculty',
  can_change_historicalfaculty: 'hierarchy.change_historicalfaculty',
  can_change_speciality: 'hierarchy.change_speciality',
  can_add_historicalfaculty: 'hierarchy.add_historicalfaculty',
  can_view_historicalacademicgroup: 'hierarchy.view_historicalacademicgroup',
  can_change_faculty: 'hierarchy.change_faculty',
  can_add_historicalspeciality: 'hierarchy.add_historicalspeciality',
  can_delete_speciality: 'hierarchy.delete_speciality',
  can_view_profile: 'hierarchy.view_profile',
  can_delete_historicalprofile: 'hierarchy.delete_historicalprofile',
  can_delete_faculty: 'hierarchy.delete_faculty',
  can_add_profile: 'hierarchy.add_profile',
  can_view_historicalspeciality: 'hierarchy.view_historicalspeciality',
};
