

















































import Vue from 'vue';
import _ from 'lodash';
import SideSheet from '@/components/SideSheet/index.vue';
import FilterGroups from '@/components/FilterGroups/index.vue';
import AutocompleteGroups from '@/components/AutocompleteGroups/index.vue';
import CardManual from '@/components/CardManual/index.vue';
import CardListGroups from '@/components/CardListGroups/index.vue';

export default Vue.extend({
  components: {
    SideSheet,
    FilterGroups,
    AutocompleteGroups,
    CardManual,
    CardListGroups,
  },
  data:() => ({
    filterQueryParams: {} as any,
    selectedGroupsAutocomplete: {} as any,
    groupsFilterAutocomplete: false,
    filterRouteName: 'groups',
  }),
  methods: {
    onSelectedFilters(selected: any) {
      this.filterQueryParams = selected;
    },
    onSelectedGroups(selected: any) {
      this.selectedGroupsAutocomplete = selected;
    },
    onFilterIsActivated(isActivated: boolean) {
      this.groupsFilterAutocomplete = isActivated;
    },
    onShowGroups() {
      this.groupsFilterAutocomplete = false;

      const vGroupsAutocomplete: any = this.$refs.groupsAutocomplete;
      const vGroupsAutocompleteInput: any = vGroupsAutocomplete.$refs.groupsAutocomplete;

      vGroupsAutocompleteInput.focus();
      vGroupsAutocompleteInput.activateMenu();
    },
  },
});
