





































import Vue from 'vue';

export default Vue.extend({
  props: {
    min: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data:() => ({
    modalEndDatePicker: false,
    selectedEndDate: null as (null | string),
  }),
  watch: {
    value: {
      handler(value: (null | string)) {
        this.selectedEndDate = value;
      },
      immediate: true,
    },
    selectedEndDate(value: (null | string)) {
      this.$emit('update:value', value);
    },
  },
  methods: {
    saveDate(selectedEndDate: string) {
      const vDialogDatePicker: any = this.$refs.dialogEndDatePicker;
      vDialogDatePicker.save(selectedEndDate);
    },
  },
});
