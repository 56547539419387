


























import Vue from 'vue';
import { Faculty } from 'typings/interface/faculty';
import RepositoryFactory from '@/api/RepositoryFactory';

const FacultiesRepository = RepositoryFactory.get('faculties');

export default Vue.extend({
  props: {
    itemValue: {
      type: Number,
      default: null,
    },
  },
  data:() => ({
    faculties: [] as Array<Faculty>,
    isLoadingFaculties: false,
    selectedFaculty: null as (null | number),
    page: 1,
  }),
  computed: {
    menuProps() {
      return {
        'contentClass': 'included',
        'max-width': 280,
      };
    },
    mdAndDown() {
      return (this.$vuetify as any).breakpoint.mdAndDown;
    },
  },
  watch: {
    itemValue: {
      handler(value: number) {
        this.selectedFaculty = value;
      },
      immediate: true,
    },
  },
  created() {
    this.getFaculties();
  },
  methods: {
    async getFaculties() {
      this.isLoadingFaculties = true;
      const queryParams = { page: this.page };
      const response = await FacultiesRepository.get(queryParams);
      this.faculties = response.data.results;
      this.isLoadingFaculties = false;
    },
    selected() {
      this.$emit('selected', this.selectedFaculty);
    },
  },
});
