const TYPES = [
  {
    id: 0,
    name: 'Чётное',
  },
  {
    id: 1,
    name: 'Нечётное',
  },
];

export default TYPES;
